
<template>
  <div class="row gy-2">
    <div class="col-12">
      <h5 class="mb-3">List of groups</h5>
      <div class="row mb-3 gy-2 justify-content-between">
        <div class="col-sm-12 col-md-6 col-lg-4 d-flex">
          <div class="dropdown no-arrow me-2">
            <a class="btn btn-light bg-white dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              <i class="bx bx-menu"></i>
            </a>
            <ul class="dropdown-menu">
              <li @click.prevent="exportClients()">
                <button class="dropdown-item" type="button">
                  Export Excel
                </button>
              </li>
            </ul>
          </div>
          <div class="input-group">
            <b-form-select style="max-width:100px;" class="form-select" v-model="itemsPerPage" :options="pageOptions"></b-form-select>
            <select @change="fetchItems()" v-model="defaultStatus" class="form-select flex-grow-1">
              <option value="" disabled selected>-- sort by status --</option>
              <option :value="null"> All </option>
              <option v-for="(status, tIndex) in statusOptions"
                :value="status.value" :key="tIndex" > {{status.label}} </option>
            </select>
          </div>
        </div>
        <div class="col-md-5">
          <div class="input-group mb-0">
            <input v-model="searchQuery" @input="updateSearchQuery" type="search" class="form-control" placeholder="Search here..." aria-label="Search here..." aria-describedby="button-search_TB">
            <button @click="fetchItems()" class="btn btn-primary" type="button" id="button-search_TB"><span class="ri ri-search-line"></span></button>
          </div>
        </div>
      </div>
      <div class="mb-3">
        <p class="mb-0">Showing {{paginationInfo}} results</p>
      </div>
      <div class="card">
        <div class="card-body p-lg-3">
          <div class="table-responsive mb-0 loading-viewport">
            <is-loading v-if="isLoading" :box="true" />
            <b-table striped hover ref="selectableTable" :items="pageData.data"
                :fields="columns" responsive="sm"  align="middle">
                <template v-for="(column, colIndex) in columns.filter((i) => i.sortable)"
                  v-slot:[`head(${column.key})`] >
                  <div :key="colIndex" @click="setSortOrder(column)" class="cursor-pointer
                  d-flex align-items-center">
                    {{ column.label }} <span v-html="getSortingLabel(column.key)"></span>
                  </div>
                </template>
                <template v-slot:cell(logo)="data">
                  <div class="cursor-pointer">
                    <img @click="showProfileImg(data.item.logo)" class="avatar rounded-circle" :src="absoluteUrl(data.item.logo)" />
                  </div>
                </template>
                <template v-slot:cell(id)="data">
                  <div style="min-width:9rem;" @click="navigateTo(data.item.id)" class="cursor-pointer">
                    {{ data.item.friendly_id }}
                  </div>
                </template>
                <template v-slot:cell(name)="data">
                  <div style="min-width:10rem;" @click="navigateTo(data.item.id)" class="cursor-pointer">
                    {{ data.item.name }}
                  </div>
                </template>
                <template v-slot:cell(head)="data">
                  <div style="min-width:15rem;" @click="navigateTo(data.item.id)"  class="cursor-pointer" v-if="data.item.head">
                    <h6 class="mb-1">{{ data.item.head.first_name }} {{ data.item.head.last_name }}</h6>
                    <span>{{ data.item.head.email }}</span>
                  </div>
                </template>
                <template v-slot:cell(tmembers)="data">
                  <div @click="navigateTo(data.item.id)"  class="cursor-pointer">
                    <span class="text-dark font-sm">{{ data.item.members_count }}</span>
                  </div>
                </template>
                <template v-slot:cell(created_at)="data">
                  <div @click="navigateTo(data.item.id)" style="width:7rem;" class="cursor-pointer">
                    {{$filters.date(data.item.created_at, 1) }} 
                  </div>
                </template>
            </b-table>
          </div>
        </div>
      </div>
      <div class="mb-4">
        <b-pagination v-model="currentPage" :limit="4" class="pagination-rounded"
        :total-rows="pageData.total" :per-page="itemsPerPage"></b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import IsLoading from '@/components/IsLoading.vue';
import _ from 'lodash';
import { commonSortingMixin } from '@/mixins/commonSortingMixin';
import { commonPaginationMixin } from '@/mixins/commonPaginationMixin';

export default {
  name: "group-list",
  components:{
    IsLoading
  },
  mixins: [
    commonSortingMixin,
    commonPaginationMixin,
  ],
  data() {
    return {
      isLoading: false,
      defaultStatus: null,
      searchQuery:"",
      statusOptions:[
        { value: "pending", label: "Pending" },
        { value: "approved", label: "Approved" }
      ],
      columns: [
        {
            key: "logo",
            label: "Logo",
        },
        {
            key: "id",
            label: "ID",
        },
        {
            key: "name",
            label: "Name",
            sortable: true
        },
        {
            key: "head",
            label: "Group head",
        },
        {
            key: "tmembers",
            label: "Total Members",
        },
        {
            key: "created_at",
            label: "Created at",
            sortable: true
        },
      ],
      sortBy: {
        sort_column: "created_at",
        sort_direction: "desc"
      },
    }
  },
  computed: {
    pageData(){
      return this.$store.state.group.pageData
    }
  },
  methods: {
    navigateTo(id){
      this.$router.push({path: `/groups/${id}`});
    },
    updateSearchQuery: _.debounce(function(string) {
      this.fetchItems()
    }, 2000),
    fetchItems(page, per_page = null){
      let payload = {page: page || 1, per_page: per_page || this.itemsPerPage,
        ...this.sortBy
      }
      if(this.defaultStatus){ payload.status = this.defaultStatus }
      if(this.searchQuery){ payload.search = this.searchQuery }
      this.isLoading = true
      this.$store.dispatch("group/fetchGroups", payload)
      .then(response => this.isLoading = false)
    },
    exportClients() {
      const payload = {
        url: '/groups/export-excel',
        filename: 'groups_'
      }
      this.$store.dispatch("changeLoaderValue", true)
      this.$store.dispatch('serverExport', payload)
        .then((response) => {
          this.$store.dispatch("changeLoaderValue", false)
          if(response.status != 200){
            this.alertError('Could not generate clients');
          }
      })
    },
    initializeAll(){
    }
  },
  mounted() {
    this.fetchItems()
  },
}
</script>

